<template>
    <div>
        <CButton color="success" square
            size="sm"
            style="float: right"
            @click="openModalEvent('create', 'alternativePromotionForm', {}, 'Yeni Alternatif Promosyon Ekle' )"
        >
            Kod Ekle
        </CButton>
        <br/>
        <CDataTable
            :items="alternativePromotions"
            :fields="fields"
            :items-per-page="10"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'alternativePromotionForm', lastItem, 'Alternatif Promosyonu Düzenle' )"
            v-on:refresh="getAltPromotion()"
            class="modalTable"
        >
            <template #show_details="{item, index}">
                <td class="py-2">
                    <CDropdown
                        toggler-text="İşlemler" color="info"
                        class="m-2"
                    >
                        <div role="group">
                            <CDropdownItem @click="openModalEvent('update', 'alternativePromotionForm', item, 'Alternatif Promosyonu Düzenle' )">Düzenle</CDropdownItem>
                            <CDropdownItem @click="openModalEvent('delete', 'confirm', item, 'Promosyon Kuralını Sil', 'Alternatif promosyonu silmek üzeresiniz. Bunu yapmak istediğinizden emin misiniz?' )">Sil</CDropdownItem>
                        </div>
                    </CDropdown>
                </td>
            </template>
            <template #validFlag="{item}">
                  <td>
                    <CIcon v-if="item.validFlag" class="green"
                           name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                  </td>
                </template>
                <template #approvedFlag="{item}">
                  <td>
                    <CIcon v-if="item.approvedFlag" class="green"
                           name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                  </td>
                </template>
                <template #cancelledFlag="{item}">
                  <td>
                    <CIcon v-if="item.cancelledFlag" class="green"
                           name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                  </td>
                </template>     
            <template #createTime="{item}">
                <td>
                   {{ dateFormat(item.createTime) }}
                </td>
            </template>
          <template #validDateStart="{item}">
            <td>
              {{ dateFormat(item.validDateStart) }}
            </td>
          </template>
          <template #validDateStart="{item}">
            <td>{{ item.validDateStart && dateFormat(item.validDateStart) }} </td>
          </template>
          <template #validDateEnd="{item}">
            <td>{{  item.validDateEnd && dateFormat(item.validDateEnd) }} </td>
          </template>
        </CDataTable> 
        <ConfirmModal
            v-if="form == 'confirm'" 
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="crud"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
        />
        <FormModal 
            v-else
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :operationEvent="crud"
            :actionType="actionType"
            :module="module"
            :form="form"
            :data="modalProps"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import FormModal from '../../components/formModal'
    import ConfirmModal from '../../components/confirmModal'
    export default{
        name: "PromotionRuleList",
        components:{
            FormModal, ConfirmModal
        },
        props:{
            params: Object,
        },
        computed:{
            alternativePromotions: function() {
                return this.$store.getters.promotionAlternative
            },
            loading: function() {
                return this.$store.getters.adminLoading
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
                this.getAltPromotion()
            },
            modalProps(val){
                if(this.modalProps && val.relatedProductType)
                    this.modalProps.relatedProductTypeId = val.relatedProductType.id
                if(this.modalProps && val.productType)
                    this.modalProps.productTypeId = val.productType.id
            }
        },
        data: function(){
            return{
                modalTitle: '',
                actionType: '',
                module: 'admin',
                form: 'promotionForm',
                modalProps: {},
                modalDesc: '',
                openModal: false,
                data: this.params ? {...this.params} : {},
                lastItem: {},
                fields: [
                {
                key: 'show_details',
                label: 'İşlemler',
                _style: 'font-size:12px'
                },
                {key: 'id', label: 'ID', _style: 'font-size:12px'},
                {key: 'createTime', label: 'Oluşturulma Tarihi', _style: 'font-size:12px'},
                {key: 'code', label: 'KOD', _style: 'font-size:12px'},
                {key: 'customerId', label: 'Müşteri', _style: 'font-size:12px'},
                {key: 'validFlag', label: 'Geçerlilik', _style: 'font-size:12px'},
                {key: 'approvedFlag', label: 'Kullanıldı', _style: 'font-size:12px'},
                {key: 'cancelledFlag', label: 'İptal', _style: 'font-size:12px'},
                {key: 'useCount', label: 'Kullanım Adeti', _style: 'font-size:12px'},
                {key: 'maxCount', label: 'Maks. Adet', _style: 'font-size:12px'},
                {key: 'totalUsedCount', label: 'Toplam Kullanım Adeti', _style: 'font-size:12px'},
                {key: 'validDateStart', label: 'İlk Geçerlilik', _style: 'font-size:12px'},
                {key: 'validDateEnd', label: 'Son Geçerlilik', _style: 'font-size:12px'},
                {key: 'validationCode', label: 'Doğrulama Kodu', _style: 'font-size:12px'},
            ]
            }
        },
        mounted: function(){
            this.getAltPromotion()
        },
        methods: {
            async openModalEvent(actionType, form, data, title, desc){


              if (actionType == 'update' && form == 'alternativePromotionForm') {
                data.validDateStart = moment(data.validDateStart).format("YYYY-MM-DD")
                data.validDateEnd = moment(data.validDateEnd).format("YYYY-MM-DD")
              }

              if (actionType == 'create' && form == 'alternativePromotionForm') {
                await this.$store.dispatch('promotionAlternative_getCode')
                data.code = this.$store.getters.promotionCode
                data.validFlag = true
              }

              this.openModal = !this.openModal
              this.actionType = actionType
              this.form = form
              this.modalProps = data
              this.modalTitle = title
              this.modalDesc = desc
          },
            getAltPromotion: async function(){
                var formData = new FormData();
                formData.append("filter" + "[0][field]", 'promocode.id');
                formData.append("filter" + "[0][data][type]", 'numeric');
                formData.append("filter" + "[0][data][value]", this.data.id);
                formData.append("filter" + "[0][data][comparison]", 'eq');
    
                await this.$store.dispatch('promotionAlternative_list', formData)

            },
            dateFormat(date){
                return moment(date).format("DD.MM.YYYY HH:mm:ss")
            },
            rowClicked(item){
                this.lastItem = item
            },
            async crud(item, action){
                let params = {
                    "actionType" : action,
                    "promoId": this.data.id,
                    "id": item.id,
                    "customerId": item.customerId,
                    "code": item.code.toUpperCase(),
                    "maxCount": item.maxCount,
                    "useCount": item.useCount,
                    "totalUsedCount": item.totalUsedCount,
                    "validDateStart": item.validDateStart,
                    "validDateEnd": item.validDateEnd,
                    "validFlag": item.validFlag,
                    "approvedFlag": item.approvedFlag,
                    "cancelledFlag": item.cancelledFlag
                };
                
                await this.$store.dispatch('promotionAlternative_action', params);
                if(this.$store.getters.adminStatus.success){
                    this.getAltPromotion()
                    this.openModalEvent()
                }
            }
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>